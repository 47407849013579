export interface AdditionalFinanceParameters {
    utm: { source: string; medium: string; campaign: string };
    leadFormType: string;
    leadFormTool: string;
    experiment: string | null;
}

export const useAdditionalFinanceParameters = (): AdditionalFinanceParameters => {
    return {
        utm: { source: 'otodom', medium: 'contactForm', campaign: 'checkbox' },
        leadFormType: 'other',
        leadFormTool: 'checkbox',
        experiment: null,
    };
};
